<template>
  <div class="ab-page">
    <!-- <Ad></Ad> -->
    <router-view></router-view>
    <!-- <FooterBanner /> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Ad from "@/components/ad.vue";
export default {
  components: {
    // MainNav,
    Footer,
    Ad,
    // FooterBanner,
  },
};
</script>

<style lang="sass" scoped>
  .ab-page {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    width: 100%;
    /* height: 7626px; */
    // overflow: hidden;
}
</style>
