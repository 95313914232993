<template>
  <div class="adArea" v-if="visible">
    <img @click="closeAd" class="bgFilter" src="../assets/home/bg-filter.png" />
    <div class="imgArea">
      <div @click="closeAd" class="closeBtn">
        <img src="../assets/home/ad-close.jpg" />
      </div>
      <a
        data-source="p"
        onmouseover="linkDataSource(this)"
        a-type="register"
        @click="showModal"
        target="_blank"
      >
        <img class="adPic" src="../assets/home/ad-pic.png" />
      </a>
    </div>
    <inquiry-modal-vue
      :visible="visible1"
      @cancel="handleCancel"
    ></inquiry-modal-vue>
  </div>
</template>
<script>
import inquiryModalVue from "@/components/InquiryModal2.vue";
export default {
  components: {
    inquiryModalVue,
  },
  name: "Ad",
  data() {
    return {
      visible: false,
      visible1: false,
    };
  },
  created() {
    this.visible = sessionStorage.getItem("adVisible") !== null ? false : true;
  },
  methods: {
    closeAd() {
      this.visible = false;
      sessionStorage.setItem("adVisible", false);
    },
    showModal() {
      window.open("https://crm.cnabke.com/#/passport/register", "_blank");
      this.visible1 = true;
    },

    handleCancel() {
      this.visible1 = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.adArea {
cursor: pointer;
.bgFilter {
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.imgArea {
  position: fixed;
  bottom: 0px;
  z-index: 1001;
  width: 100%;
}
  .adPic {
    /*position: fixed;*/
    /*bottom: 0px;*/
    width: 100%;
    z-index: 1001;
  }
  .closeBtn {
    /*position: fixed;*/
    /*bottom: 400px;*/
    z-index: 1002;
    right: 0px;
    /*height: 100px;*/
    text-align: right;
    img {
      width: 50px;
      margin-bottom: 30px;
      margin-right: 40px;
    }
  }
}
</style>
